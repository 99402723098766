import React from "react";
import {PersonalInformation} from "./../../../../const"
import profilePic from '../../../../assets/img/profile.jpeg';
import styles from "./personalInfo.module.scss"
import TechStack from "../TechStack";
const PersonalInfo = () =>{
    return <div className={styles.personalInfo}>
    <div className={styles.personalInfo__information}>
      <span className={styles.personalInfo__information__heading}>Hello,</span>
      <span className={styles.personalInfo__information__heading}>I'm <span>Paras Kumar</span>.</span>
      <span className={styles.personalInfo__information__heading}>I'm a <span>Frontend Developer</span> 🚀 </span>
      <TechStack />
    </div>
    <div className={styles.personalInfo__image}>
       <img className={styles.profilePic} loading="eager" src={profilePic}/>
    </div>
    </div>
}

export default PersonalInfo;
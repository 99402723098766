import React from "react"
import css from "./../../../../assets/img/cssfinal.png"
import html from "./../../../../assets/img/htmlfinal.png"
import react from "./../../../../assets/img/reactfinal2.png"
import js from "./../../../../assets/img/jsfinal.png"
import sass from "./../../../../assets/img/sassfinal.png"
import styles from "./techStack.module.scss"
const TechStack = ()=>{
    
    return <div className={styles.skills}>
            <img src={html} />
            <img src={css} />
            <img src={js} />
            <img src={react} />
            <img src={sass} />
        </div>
}

export default TechStack
import React from "react"
import styles from "./contacts.module.scss"
const Contact = () =>{
return <div id="contact" className={styles.contact}>
    <h3>Contact</h3>
    <h2>Don't be shy! Hit me up! 👇</h2>
    <section>
        <h4>+919914926910</h4>
        <h4>kumarparas249@gmail.com</h4>
    </section>
</div>
}
export default Contact;
import React from "react";
import styles from "./about.module.scss";
const About = () => {
  return (
    <div id="about" className={styles.aboutSection}>
      <h3>About</h3>
      <div className={styles.aboutSection__content}>
      <p>
        Hello! My name is Paras Kumar and I am a Frontend Developer from India.
        Skilled in creating dynamic, interactive boxes for seamless user experiences across platforms leveraging modern technologies and frameworks to develop impactful solutions that drive business growth. When I am not wirting logic, I travel and explore new places.
      </p>
      </div>
    </div>
  );
};

export default About;

import "./App.css";
import { BrowserRouter as Router, Route, Routes, createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Pages/Home"
import Layout from "./Components/Layout";
import { Suspense } from "react";
function App() {
  const router = () =>{
    return createBrowserRouter([
      {
        path: '/',
        element: <Layout/>,
        children:[
          {
            index:true,
            element:<Home/>
          }
        ]
      }
    ])
  }
  return (
    <Suspense>
      <RouterProvider router={router()} />
    </Suspense>
  );
}

export default App;

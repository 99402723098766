import React, { useState } from 'react';
import styles from  './navbar.module.scss'; // Import the SCSS file
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  
  const focusArea=()=>{

  }
  const cvLink = "https://docs.google.com/document/d/1qbwsvxXV8sKjZL6cBWrosu3Jv_iOJQz12cgJdGSokOM/edit?usp=sharing";
  return (
    <>
    <header className={styles.navbar}>
      <div className={styles.navbar__logo}>pks.dev</div>
      <button className={styles.navbar__toggle} aria-label="Toggle navigation" onClick={toggleMenu}>
        &#9776;
      </button>
      <nav className={`${styles.navbar__menu} ${isOpen ? styles['navbar__menu--open'] : ''}`} onClick={focusArea}>
          {/* <span className={styles.navbar__menu__item}><a href="#">Home</a></span> */}
          <span className={styles.navbar__menu__item}><a href="#" id="About">About</a></span>
          {/* <span className={styles.navbar__menu__item}><a href="#" id="projects">Projects</a></span> */}
          <span className={styles.navbar__menu__item}><a href="#" id="contact">Contact</a></span>
          <span><a href={cvLink} target='_blank'  className={styles.navbar__menu__item__downloadButton} download={cvLink}>Download CV</a></span>
      </nav>
    </header>
    </>
  );
};

export default Navbar;

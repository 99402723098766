import React from "react";
import styles from "./socialSection.module.scss";
import linkedIn from "./../../../../assets/img/icons8-linkedin-100.png";
import github from "./../../../../assets/img/icons8-github-48.png";
import codeSandBox from "./../../../../assets/img/codeSandbox.png";
const SocialSection = () =>{

    return <div className={styles.socialSection}>
        <div className={styles.bar}></div>
        <a href="https://www.linkedin.com/in/soniparas/" target="_blank">
        <img src={linkedIn} />
      </a>
      <a href="https://github.com/kumarParas7477" target="_blank"><img src={github} /></a>
      <a href="https://codesandbox.io/u/ParasKumar" target="_blank"><img src={codeSandBox}/></a>
    </div>

}
export default SocialSection;
import React from "react";
import Navbar from "./../Navbar";
import { Outlet } from "react-router-dom";
import styles from "./layout.module.scss"
const Layout = () => {
  return (
    <>
      <Navbar />
      <div className={styles.outletContainer}>
      <Outlet />
      </div>
    </>
  );
};

export default Layout;

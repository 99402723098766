import React from "react";
import PersonalInfo from "./Components/PersonalInfo"
import Contact from "./Components/Contact";
import About from "./Components/About";
import SocialSection from "./Components/SocialSection";
import styles from "./home.module.scss";
const Home = () => {
  return (
    <div className={styles.main}>
      
      <PersonalInfo />
      <SocialSection />
      <About/>
      <Contact/>
    </div>
  );
};

export default Home;
